export interface State {
  language: string;
  isMobileMenuOpen: boolean;
}

export enum Actions {
  setLanguage = "SET_LANGUAGE",
  setMobileMenuIsOpen = "SET_MOBILE_MENU_IS_OPEN",
}

interface Action {
  type: Actions;
  payload: Partial<State>;
}

export const initialState = {
  language: "fr",
  isMobileMenuOpen: false,
};

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case Actions.setLanguage:
      // console.log("SET_LANGUAGE", payload);

      return {
        ...state,
        language: payload.language ?? "fr",
      };

    case Actions.setMobileMenuIsOpen:
      // console.log("SET_MOBILE_MENU_IS_OPEN", payload);
      return {
        ...state,
        isMobileMenuOpen: payload.isMobileMenuOpen ?? false,
      };
    default:
      throw new Error(`No case for type ${type} found in reducer.`);
  }
};

export default reducer;
