import React, { ReactNode, createContext, useReducer, useContext } from "react";

import reducer, { Actions, initialState } from "./mainReducer";

type ContextProps = {
  children: ReactNode;
};

type ContextValue = {
  isMobileMenuOpen: boolean;
  setMobileMenu: (isOpen: boolean) => void;
  language: string;
  setLanguage: (language: string) => void;
};

const Context = createContext<ContextValue | undefined>(undefined);

export function MainProvider({ children }: ContextProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setMobileMenu = (isOpen: boolean) => {
    dispatch({
      type: Actions.setMobileMenuIsOpen,
      payload: {
        isMobileMenuOpen: isOpen,
      },
    });
  };

  const setLanguage = (language: string) => {
    dispatch({
      type: Actions.setLanguage,
      payload: {
        language,
      },
    });
  };

  const value = {
    isMobileMenuOpen: state.isMobileMenuOpen,
    setMobileMenu,
    language: state.language,
    setLanguage,
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

const useMainContext = () => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error("useMainContext must be used within a MainProvider.");
  }

  return context;
};

export default useMainContext;
