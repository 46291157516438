import React from "react";
import { MainProvider } from "./context/mainContext";
// import { ModalProvider } from "./context/modalContext"

interface Props {
  element: React.ReactNode;
}

const CombinedProvider = ({ element }: Props) => {
  return <MainProvider>{element}</MainProvider>;
};

export default CombinedProvider;
